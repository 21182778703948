import React, { FC, PropsWithChildren, useMemo, useState } from 'react';

import { useUserDataStore } from '@store/storeshed';
import { getUserPlan } from '@utils/_getUserPlan';

import { ePaywallPopupStep, ePaywallPopupType } from '../_types';

import { PaywallPopupContext } from './_context';
import {
  IPaywallPopupContextProviderProps,
  IPaywallPopupContextValue,
} from './_types';
import { ePriceId } from '@types';

export const PaywallPopupContextProvider: FC<
  PropsWithChildren<IPaywallPopupContextProviderProps>
> = (props) => {
  const [type] = useState(props.type || ePaywallPopupType.REGISTRATION);
  const [step, setStep] = useState(props.step || ePaywallPopupStep.INFO);
  const [plan, setPlan] = useState(props.plan || ePriceId.PRO_ANNUAL);
  const [awaitingPaypal, setAwaitingPaypal] = useState(false);

  const userData = useUserDataStore('data');
  const userPlan = useMemo(() => getUserPlan(userData), [userData]);

  const value = useMemo<IPaywallPopupContextValue>(() => {
    return {
      userPlan,
      type,
      step,
      plan,
      awaitingPaypal,
      setStep,
      setPlan,
      setAwaitingPaypal,
    };
  }, [userPlan, type, step, plan, awaitingPaypal]);

  return (
    <PaywallPopupContext.Provider value={value}>
      {props.children}
    </PaywallPopupContext.Provider>
  );
};
