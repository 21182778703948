import { IUserData } from '@types';

import { getUserStatus } from './_getUserStatus';
import { getUserFideStatus } from './_getUserFideStatus';
import { UserStatus } from './hooks/_useUserSatatus';

export const getIsUserAllowedToPlayFOAGames = (userData: IUserData | null) => {
  if (!userData) return false;

  const userStatus = getUserStatus({
    accessLevel: userData.access_level,
  });

  return userStatus === UserStatus.SUBSCRIBED && getUserFideStatus(userData);
};
