import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { BaseButton, BaseInput } from '../../../atoms';
import { $Content, $Form, $Title, $Text, inputStyles, buttonStyles, $ActivationCodeError, } from './_styles';
import { eActivationErrorCode } from '../../../../types';
import { useCountdown } from '../../../../hooks/_useCountdown.hook';
import { formatNumber } from '../../../../helpers/_common';
import { useForm } from '../../../../hooks/_useForm.hook';
import { emailIsNotValid } from '../../../../utils/_validators';
export const PasswordResetForm = ({ resendActivationCode, setShowPasswordForm, activationCode, activationCodeError, setActivationCodeError, l, }) => {
    const { milliseconds: disabledMs, setMilliseconds: setDisabledMs } = useCountdown({
        timeInMilliseconds: 0,
    });
    const localization = useMemo(() => {
        return Object.assign({ common: { email: 'Email', seconds: 'seconds' }, registration: {
                code: 'Code',
                password_reset_message: 'Enter the email you used to create an account. We will send you a confirmation code to reset your password',
                enter_confirmation: 'Enter the confirmation code we sent to ',
                password_reset: 'Password reset',
            }, popups: {
                activation: {
                    invalid_code: 'Invalid code. Make sure that the code matches the one we sent you or resend code',
                    throttled: 'Too many tries. Try again in',
                },
            } }, l);
    }, [l]);
    const [showInputCode, setShowInputCode] = useState(false);
    const errorText = useMemo(() => {
        if (!activationCodeError)
            return null;
        if (typeof activationCodeError === 'object') {
            switch (activationCodeError.code) {
                case eActivationErrorCode.THROTTLED:
                    const seconds = formatNumber(disabledMs, 0);
                    return seconds
                        ? `${localization.popups.activation.throttled} ${seconds} ${localization.common.seconds}`
                        : null;
                case eActivationErrorCode.INVALID_CODE:
                    return localization.popups.activation.invalid_code;
                default:
                    return activationCodeError.detail;
            }
        }
        return activationCodeError;
    }, [activationCodeError, localization, disabledMs]);
    const showInputCodes = () => {
        setShowInputCode(!showInputCode);
    };
    const showForm = (props) => {
        if (setShowPasswordForm) {
            setShowPasswordForm(props);
        }
    };
    const handleSubmit = (state) => {
        if (!inputState.email.errorMessage &&
            !showInputCode &&
            resendActivationCode) {
            resendActivationCode({
                email: state.email.value,
                showForm: showInputCodes,
            });
        }
        if (showInputCode && activationCode) {
            activationCode({
                code: state.code.value,
                nextStep: showForm,
                email: state.email.value,
            });
        }
    };
    const { inputState, onChange, onSubmit } = useForm({
        email: {
            value: '',
            required: true,
            validator: (val) => emailIsNotValid(val),
        },
        code: {
            value: '',
            required: false,
            formatter: (val) => val.replace(/\D/g, '').substring(0, 6),
        },
    }, handleSubmit);
    useEffect(() => {
        if (activationCodeError && setActivationCodeError) {
            setActivationCodeError(null);
        }
    }, [inputState.email.value, inputState.code.value]);
    useEffect(() => {
        if (typeof activationCodeError === 'object' && (activationCodeError === null || activationCodeError === void 0 ? void 0 : activationCodeError.time)) {
            setDisabledMs(activationCodeError.time * 1000);
        }
    }, [activationCodeError]);
    return (React.createElement($Content, null,
        React.createElement($Title, null, localization.registration.password_reset),
        React.createElement($Form, { onSubmit: onSubmit },
            !showInputCode && (React.createElement($BaseInput, { name: "email", type: "email", placeholder: localization === null || localization === void 0 ? void 0 : localization.common.email, theme: "login", errorText: inputState.email.errorMessage, value: inputState.email.value, onChange: onChange })),
            showInputCode && (React.createElement($BaseInput, { name: "code", type: "text", placeholder: localization === null || localization === void 0 ? void 0 : localization.registration.code, theme: "login", errorText: inputState.code.errorMessage, value: inputState.code.value, onChange: onChange, showError: !!activationCodeError })),
            React.createElement($ActivationCodeError, null, errorText),
            React.createElement($Text, null, !showInputCode
                ? localization === null || localization === void 0 ? void 0 : localization.registration.password_reset_message
                : `${localization === null || localization === void 0 ? void 0 : localization.registration.enter_confirmation} ${inputState.email.value}`),
            React.createElement($BaseButton, { type: "submit", theme: "white", disabled: showInputCode
                    ? !inputState.code.value.length ||
                        !!inputState.code.errorMessage ||
                        disabledMs !== 0
                    : !inputState.email.value.length ||
                        !!inputState.email.errorMessage }, "Continue"))));
};
const $BaseButton = styled(BaseButton) `
  ${buttonStyles}
`;
const $BaseInput = styled(BaseInput) `
  ${inputStyles}
`;
