import React, { Fragment, memo } from 'react';
import { ILocalization } from '@types';

import { TPasswordPopupType } from '@types';
import { ePopupPaths } from '@constants';
import { usePopupsContext } from '@store/context/popupsContext/_context';
import styles from '../_password_popup.module.scss';

interface IHeader {
  popupType: TPasswordPopupType | undefined;
  l: ILocalization | null;
  prevStep: () => void;
}

export const Header = memo(function Header({
  popupType,
  l,
  prevStep,
}: IHeader) {
  const {
    computed: {
      registration: { paid },
    },
  } = usePopupsContext();

  switch (popupType) {
    case ePopupPaths.PASSWORD:
      return (
        <Fragment>
          <div className={styles.heading}>
            <h3 className={styles.title}>
              {paid
                ? l?.registration.new_pro_account
                : l?.registration.new_account}
            </h3>
          </div>
          <div className={styles.subheading}>
            <p className={styles.info_text}>
              {`${l?.registration.enter_and_confirm}`}
            </p>
            <div className={styles.nav}>
              <div className={styles.back} onClick={() => prevStep()} />
              <div className={styles.steps}>{`3 / ${paid ? 5 : 3}`}</div>
            </div>
          </div>
        </Fragment>
      );
    case ePopupPaths.PASSWORD_RESET:
      return <h3 className={styles.title}>{l?.registration.password_reset}</h3>;
    case ePopupPaths.PASSWORD_CHANGE:
      return (
        <h3 className={styles.title}>{l?.registration.password_change}</h3>
      );
    case ePopupPaths.SET_PASSWORD:
      return (
        <Fragment>
          <div className={styles.heading}>
            <h3 className={styles.title}>{l?.registration.new_account}</h3>
          </div>

          <div className={styles.subheading}>
            <p className={styles.set_password_text}>
              {l?.registration.welcome_to_chessarena}
            </p>
          </div>
        </Fragment>
      );

    default:
      return null;
  }
});
