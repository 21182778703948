import { InputEventParams } from '@chessarena/components/lib/inputs/types';
import { ePopupPaths } from '@constants';
import { eLastMoveTheme, ePossibleMovesTheme } from 'chessgun/ui';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import {
  eAutoPromotionTheme,
  eBoardThemes,
  ePremovesTheme,
  eSoundsTheme,
} from '@types';
import {
  IChessboardSettingsContextDispatch,
  IChessboardSettingsContextState,
  eChessboardSettingsContextActionType,
} from './_types';
import { useUserPaidSubscription } from './hooks/_useUserPaidSubscription.hook';
import { boardSettingsActions } from '@store/storeshed';
import { IChessboardSettings } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';
import { openPopup, closePopup } from '@utils/_router';
import { ePaywallPopupType } from '@components/molecules/popups/PaywallPopup/_types';

export const useBoardSettingsActions = (
  state: IChessboardSettingsContextState,
  dispatch: IChessboardSettingsContextDispatch
) => {
  const { updateBoardSettings } = boardSettingsActions;
  const userHasPaidSubscription = useUserPaidSubscription();
  const userIsLogged = !!useUserDataStore('data');
  const router = useRouter();

  const setOpened = useCallback(
    (isOpened: boolean) => {
      dispatch({
        key: eChessboardSettingsContextActionType.SET_OPENED,
        payload: isOpened,
      });
    },
    [dispatch]
  );

  const setCurrentSettings = useCallback(
    (currentSettings: IChessboardSettings) => {
      dispatch({
        key: eChessboardSettingsContextActionType.UPDATE_SETTINGS,
        payload: currentSettings,
      });
    },
    [dispatch]
  );

  const setChessboardTheme = useCallback(
    (theme: eBoardThemes) => {
      dispatch({
        key: eChessboardSettingsContextActionType.UPDATE_SETTING,
        payload: { key: 'boardTheme', value: theme },
      });
    },
    [dispatch]
  );

  const setLastMoveTheme = useCallback(
    (theme: eLastMoveTheme) => {
      dispatch({
        key: eChessboardSettingsContextActionType.UPDATE_SETTING,
        payload: { key: 'lastMoveTheme', value: theme },
      });
    },
    [dispatch]
  );

  const setLegalMoves = useCallback(
    (theme: ePossibleMovesTheme) => {
      dispatch({
        key: eChessboardSettingsContextActionType.UPDATE_SETTING,
        payload: { key: 'legalMovesTheme', value: theme },
      });
    },
    [dispatch]
  );

  const setSoundsTheme = useCallback(
    (checked: boolean) => {
      dispatch({
        key: eChessboardSettingsContextActionType.UPDATE_SETTING,
        payload: {
          key: 'soundsTheme',
          value: checked ? eSoundsTheme.ON : eSoundsTheme.OFF,
        },
      });
    },
    [dispatch]
  );

  const setPremovesTheme = useCallback(
    (checked: boolean) => {
      dispatch({
        key: eChessboardSettingsContextActionType.UPDATE_SETTING,
        payload: {
          key: 'premovesTheme',
          value: checked ? ePremovesTheme.ON : ePremovesTheme.OFF,
        },
      });
    },
    [dispatch]
  );

  const setAutoPromotionTheme = useCallback(
    (checked: boolean) => {
      dispatch({
        key: eChessboardSettingsContextActionType.UPDATE_SETTING,
        payload: {
          key: 'autoPromotion',
          value: checked ? eAutoPromotionTheme.ON : eAutoPromotionTheme.OFF,
        },
      });
    },
    [dispatch]
  );

  const onSubscribeToUnlockClick = useCallback(() => {
    if (!userIsLogged) {
      setTimeout(() => {
        openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
      }, 10);
      return;
    }

    setTimeout(() => {
      openPopup(
        `?popup=${ePopupPaths.PAYWALL}&type=${ePaywallPopupType.GAMES}`
      );
    }, 10);
    return;
  }, [userIsLogged]);

  const saveBoardSettings = useCallback(() => {
    const {
      autoPromotion,
      boardTheme,
      lastMoveTheme,
      legalMovesTheme,
      premovesTheme,
      soundsTheme,
    } = state.currentSettings;

    const paidThemes = [
      eBoardThemes.AQUA,
      eBoardThemes.BUBBLEGUM,
      eBoardThemes.CURRY,
      eBoardThemes.HIPSTER,
      eBoardThemes.LEMON_TREE,
    ];

    if (!userHasPaidSubscription && paidThemes.includes(boardTheme)) {
      if (!userIsLogged) {
        setTimeout(() => {
          openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
        }, 10);
        return;
      }

      setTimeout(() => {
        openPopup(
          `?popup=${ePopupPaths.PAYWALL}&type=${ePaywallPopupType.GAMES}`
        );
      }, 10);
      return;
    }

    updateBoardSettings({
      soundTheme: soundsTheme,
      lastMoveTheme,
      premovesTheme,
      chessboardTheme: boardTheme,
      autoPromotionTheme: autoPromotion,
      legalMovesTheme,
    });
  }, [
    state,
    updateBoardSettings,
    userHasPaidSubscription,
    router,
    userIsLogged,
  ]);

  const resetToPlayerSettings = useCallback(() => {
    const { playerCurrentSettings } = state;

    setCurrentSettings(playerCurrentSettings);
  }, [setCurrentSettings, state]);

  const openPopupHandler = useCallback(() => {
    setOpened(true);
  }, [setOpened]);

  /**
   * Закрывает попап
   */
  const closePopupHandler = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const closePopupAndResetSettings = useCallback(() => {
    resetToPlayerSettings();
    closePopupHandler();
  }, [closePopupHandler, resetToPlayerSettings]);

  /**
   * Закрывает попап И сохраняет настройки
   */
  const closePopupAndSaveHandler = useCallback(() => {
    saveBoardSettings();
    closePopup();
  }, [saveBoardSettings]);

  const onBoardChange = useCallback(
    (value: eBoardThemes) => {
      setChessboardTheme(value as eBoardThemes);
    },
    [setChessboardTheme]
  );

  const onLastMovesChange = useCallback(
    ({ value }: InputEventParams) => {
      setLastMoveTheme(String(value) as eLastMoveTheme);
    },
    [setLastMoveTheme]
  );

  const onLegalMovesChange = useCallback(
    ({ value }: InputEventParams) => {
      setLegalMoves(String(value) as ePossibleMovesTheme);
    },
    [setLegalMoves]
  );

  const onSoundsSwitchChange = useCallback(
    (checked: boolean) => {
      setSoundsTheme(checked);
    },
    [setSoundsTheme]
  );

  const onPremoveSwitchChange = useCallback(
    (checked: boolean) => {
      setPremovesTheme(checked);
    },
    [setPremovesTheme]
  );

  const onAutoPromotionSwitchChange = useCallback(
    (checked: boolean) => {
      setAutoPromotionTheme(checked);
    },
    [setAutoPromotionTheme]
  );

  return {
    saveBoardSettings,
    openPopupHandler,
    closePopupHandler,
    closePopupAndResetSettings,
    closePopupAndSaveHandler,
    onBoardChange,
    onLastMovesChange,
    onLegalMovesChange,
    onSoundsSwitchChange,
    onPremoveSwitchChange,
    onAutoPromotionSwitchChange,
    onSubscribeToUnlockClick,
  };
};
