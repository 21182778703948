import React from 'react';
import { useApplicationContext } from '@application';
import styles from './_heading.module.scss';
import { FideRatingIcon, LogoIcon } from 'shared/atoms';
import { LazyImage } from 'shared/molecules';
import { GameRatingMode } from '@types';
import { useTournamentStore } from '@store/storeshed';

const mediumDefaultImage =
  'https://gaming-images.worldchess.com/media/tournament/default_image/medium.png';

export const Heading: React.FC = () => {
  const { localization: l } = useApplicationContext();
  const tournamentData = useTournamentStore('tournament_data');

  if (!tournamentData) return null;

  return (
    <div className={styles.heading}>
      <div className={styles.image_comtainer}>
        <LazyImage
          src={tournamentData.main_image?.medium || mediumDefaultImage}
          className={styles.title_image}
        />
      </div>

      <p className={styles.title_text}>
        <span>{l.popups.tournament_payment.tournament_with_entry_fee}</span>
        {tournamentData.rating_type === GameRatingMode.FIDERATED ? (
          <span className={styles.icon_text}>
            <FideRatingIcon fill="black" className={styles.fide_icon} />
            {l.common.foa}
          </span>
        ) : (
          <span className={styles.icon_text}>
            <LogoIcon fill="black" className={styles.wc_icon} />
            {l.navbar.world_chess}
          </span>
        )}
        <span>{`${l.popups.tournament_payment.rated} ${tournamentData.title}`}</span>
      </p>
    </div>
  );
};
