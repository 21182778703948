import { AccountVerification, IUserData } from '@types';

export enum eUserFideStatus {
  VERIFIED = 'VERIFIED',
  NOT_VERIFIED = 'NOT_VERIFIED',
}

export const getUserFideStatus = (userData: IUserData | null) => {
  if (
    userData &&
    userData.fide_id &&
    userData.fide_verified_status !== AccountVerification.ON_CHECK
  ) {
    return eUserFideStatus.VERIFIED;
  }

  return eUserFideStatus.NOT_VERIFIED;
};
