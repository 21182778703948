import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { BasePopup, ImageUpload } from 'shared/atoms';
import { LoadingBaseButton } from 'shared/molecules';
import { IUpdateFideError, IUpdateFide } from '@types';
import styles from './_fide_verification_popup.module.scss';
import { fideSubscriptionActions } from '@store/storeshed';
import { useFideSubscriptionStore } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';
import { openPopup } from '@utils/_router';
import { useApplicationContext } from '@application';
import { usePopupsContext } from '@store/context/popupsContext/_context';

export const FideVerificationPopup: React.FC = () => {
  const router = useRouter();
  const from = router.query.from as string;

  const { localization: l } = useApplicationContext();
  const userData = useUserDataStore('data');
  const inRequest = useFideSubscriptionStore('fide_request');

  const {
    state: {
      fide: { currentTab, fideId, photo },
    },
    computed: {
      fide: {
        freePro,
        formData: { inputState },
      },
    },
    actions: { closeFidePopup },
  } = usePopupsContext();

  const [showErrors, setShowErrors] = useState(false);
  const [selfie, setSelfie] = useState('');
  const [error, setError] = useState<string | null>(null);

  const successCallback = (): void => {
    if (from) {
      openPopup(`${router.pathname}?${from}`);
    } else {
      closeFidePopup();
    }
  };

  const errorCallback = (error: IUpdateFideError): void => {
    if (error) {
      if (typeof error.fide_id !== 'string') {
        fideSubscriptionActions.setFindFideUsers(null);
        prevStep();

        return;
      }

      if (!('national_id_selfie' in error)) {
        prevStep();
      } else {
        setError('Please try uploading another photo');
      }
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    e.preventDefault();

    if (!selfie.length) {
      setShowErrors(true);
      return;
    }

    let data: IUpdateFide | null = null;
    if (fideId && currentTab === 'i_have') {
      data = {
        national_id_selfie: selfie,
        photo: photo,
        fide_id: fideId,
      };
    } else {
      data = {
        full_name: `${inputState.first_name.value} ${inputState.last_name.value}`,
        first_name: inputState.first_name.value,
        last_name: inputState.last_name.value,
        date_of_birth: inputState.date_of_birth.value,
        place_of_birth: inputState.place_of_birth.value,
        federation: inputState.federation.value,
        nationality: inputState.nationality.value,
        photo: photo,
        is_male: inputState.gender.value === 'male',
        national_id_selfie: selfie,
        email: userData?.email ? userData.email : '',
      };
    }

    if (freePro && !userData?.free_account_requested) {
      await fideSubscriptionActions.updateFreeAccount(true);
    }

    fideSubscriptionActions.registrationFide(
      data,
      successCallback,
      errorCallback
    );
  };

  const prevStep = (): void => {
    const params = new URLSearchParams();
    params.append('popup', 'fide');
    if (from) {
      params.append('from', from);
    }
    if (freePro) {
      params.append('free_pro', 'true');
    }

    openPopup(`${params.toString()}`);
  };

  useEffect(() => {
    if (selfie.length && error) {
      fideSubscriptionActions.setFideError(null);
    }
  }, [selfie]);

  useEffect(() => {
    if (userData && userData.fide_id) {
      closeFidePopup();
    }
  }, [userData]);

  return (
    <BasePopup
      className={styles.popup}
      setShowPopup={closeFidePopup}
      color="pink"
    >
      <div className={styles.container}>
        <div className={styles.heading}>
          <h3 className={styles.title}>{l.registration.new_pro_account}</h3>
        </div>

        <div className={styles.subheading}>
          <p className={styles.info_text}>
            {l.registration.for_verification_selfie}
          </p>
        </div>

        <div className={styles.form}>
          <div className={styles.form_left}>
            <div className={styles.info_image}>
              <img
                className={styles.selfie}
                src="https://gaming-images.worldchess.com/media/popups/registration/selfie.svg"
                alt="selfie"
              />
            </div>

            <p className={styles.hint_text}>
              {l.registration.hold_your_passport}
            </p>
            <p className={styles.hint_text}>{l.registration.the_id}</p>
          </div>
          <div className={styles.form_right}>
            <ImageUpload
              theme="primary"
              changeHandler={setSelfie}
              showError={showErrors && !selfie.length}
            />
          </div>
        </div>

        {error && <div className={styles.form_error}>{error}</div>}

        <div className={styles.bottom}>
          <LoadingBaseButton
            theme="white"
            buttonType="primary"
            type="submit"
            className={styles.complete_button}
            disabled={!selfie}
            onClick={handleSubmit}
            loading={inRequest}
          >
            {l.registration.send_selfie}
          </LoadingBaseButton>

          <div className={styles.bottom_info}>
            {l.registration.we_will_review}
          </div>

          <div className={styles.mobile_bottom_info}>
            {l.registration.we_will_review}
          </div>
        </div>
      </div>
    </BasePopup>
  );
};
