/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { ePriceId } from '@types';

interface IButtonStyles {
  shape?: 'rect' | 'pill';
  color?: 'gold' | 'blue' | 'silver' | 'white' | 'black';
  layout?: 'horizontal' | 'vertical';
  label?: 'checkout' | 'paypal' | 'buynow' | 'pay' | 'installment';
  size?: 'small';
  tagline?: 'false' | 'true';
  height?: number;
}

const style: IButtonStyles = {
  height: 55,
};

const plansIdsMap = {
  [ePriceId.PRO_ANNUAL]: process.env.NEXT_PUBLIC_PAYPAL_ANNUAL_PLAN_ID,
  [ePriceId.PRO_MONTHLY]: process.env.NEXT_PUBLIC_PAYPAL_MONTHLY_PLAN_ID,
};

/**
 * Хук для инициализации и обработки платежей через paypal
 * @param {string} userId - данные для оплаты (user_uid)
 * @param {string} plan - данные для оплаты
 * @returns {boolean} paypalScriptLoaded - загружен ли скрип пейпала
 * @returns {boolean} paypalInited - инициализирован ли скрипт пейпала
 */
export function usePaywallPopupPaypal({
  userId,
  plan,
  open,
  onClick,
  onClose,
  onSuccess,
}: {
  userId: string;
  plan: ePriceId;
  open?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  onSuccess?: () => void;
}): { paypalLoaded: boolean; paypalInitialized: boolean } {
  const [paypalStartLoad, setPaypalStartLoad] = useState(false);
  const [paypalLoaded, setPaypalScriptLoaded] = useState(false);
  const [paypalInitialized, setPaypalInitialized] = useState(false);

  useEffect(() => {
    if (paypalStartLoad) return;
    setPaypalStartLoad(true);

    const paypalScript = document.getElementById('paywall_popup_paypal_script');

    if (!paypalScript) {
      const paypalClientId = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID;

      const script = document.createElement('script');
      script.id = 'paywall_popup_paypal_script';
      script.async = true;
      script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&components=buttons&vault=true&intent=subscription`;
      script.dataset.namespace = 'paywall_popup_paypal';

      document.body.appendChild(script);

      script.addEventListener('load', function () {
        setPaypalScriptLoaded(true);
      });
    } else {
      setPaypalScriptLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userId || !open || !paypalLoaded) return;

    const planId = plansIdsMap[plan];

    setPaypalInitialized(false);

    const buttonContainer = document.getElementById(
      'paywall_popup_paypal_button_container'
    );

    if (buttonContainer) buttonContainer.innerHTML = '';

    try {
      (window as any).paywall_popup_paypal
        .Buttons({
          style,

          onInit: function () {
            setPaypalInitialized(true);
          },

          onClick: function () {
            if (onClick) onClick();
          },

          onCancel: function () {
            if (onClose) onClose();
          },

          createSubscription: function (_data: any, actions: any) {
            return actions.subscription.create({
              custom_id: `${userId},${plan}`,
              plan_id: planId,
            });
          },

          onError: function (err: string) {
            console.log('onError', err);
            if (onClose) onClose();
          },

          onApprove: async function (_data: any, actions: any) {
            if (actions.subscription) {
              if (onSuccess) onSuccess();
            }
          },
        })
        .render('#paywall_popup_paypal_button_container');
    } catch (error) {
      console.log('paypal init error', error);
    }
  }, [
    setPaypalScriptLoaded,
    onSuccess,
    plan,
    userId,
    open,
    paypalLoaded,
    onClick,
  ]);

  return { paypalLoaded, paypalInitialized };
}
