import { ePriceId, IUserData } from '@types';

export const getUserPlan = (userData: IUserData | null) => {
  if (userData?.access_level === 2) {
    if (userData?.recurring_purchase?.price_stripe_id)
      return userData?.recurring_purchase?.price_stripe_id;
    return ePriceId.PRO_ANNUAL;
  }
  return null;
};
