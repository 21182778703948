import React, { useCallback, useEffect } from 'react';
import { IPopup } from '@types';
import { product } from '@constants';
import styles from './_form.module.scss';
import { getPrice, formatNumber } from 'shared/helpers/_common';
import { UseFormState } from 'shared/hooks/_useForm.hook';
import { PaymentForm, LoadingBaseButton } from 'shared/molecules';
import { ICardInputs } from '@types';
import { PaymentInfo } from './_PaymentInfo';
import { paymentActions } from '@store/storeshed';
import { useApplicationContext } from '@application';
import { usePaymentStore } from '@store/storeshed';

interface IForm extends IPopup {
  fideId: number;
  gameId: string;
}

export const Form: React.FC<IForm> = ({ fideId, gameId, closePopup }) => {
  const { localization: l } = useApplicationContext();
  const paymentRequest = usePaymentStore('buy_title_request');
  const paymentError = usePaymentStore('payment_error');
  const promocodeRequest = usePaymentStore('promocode_request');
  const promocodeError = usePaymentStore('promocode_error');
  const promocode = usePaymentStore('promocode');

  const promocodeHandler = useCallback(
    (coupon: string): void => {
      if (promocodeRequest) return;

      paymentActions.checkCoupon(coupon);
    },
    [promocodeRequest]
  );

  const successCallback = async (): Promise<void> => {
    closePopup();
  };

  const handleSubmit = (form: UseFormState<ICardInputs>): void => {
    if (
      paymentRequest ||
      !process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY ||
      !product
    )
      return;

    paymentActions.buyProduct({
      stripe_key: process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY,
      card: form,
      sku: product.stripe_id,
      coupon: promocode?.id,
      successCallback,
      gameId,
      fideId,
    });
  };

  const resetPaymentError = useCallback((): void => {
    paymentActions.setPaymentError(null);
  }, []);

  useEffect(() => {
    return () => {
      paymentActions.setPromocode(null);
    };
  }, []);

  return (
    <PaymentForm
      className={styles.popup_payment}
      paymentInfo={<PaymentInfo />}
      onSubmit={handleSubmit}
      promocodeHandler={promocodeHandler}
      paymentRequest={paymentRequest}
      promocodeRequest={promocodeRequest}
      promocodeApplied={!!promocode?.percentOff}
      promocodeError={promocodeError}
      error={paymentError}
      resetPaymentError={resetPaymentError}
      showPaypal={false}
      theme="white"
      button={
        <LoadingBaseButton
          theme="bluish_green"
          className={styles.payment_button}
          loading={paymentRequest}
          disabled={false}
        >
          <span className={styles.button_text}>{l.popups.common.checkout}</span>
          <span className={styles.price}>
            {`€${getPrice(
              formatNumber(product.price / 100, 0),
              promocode?.percentOff
            )}`}
          </span>
        </LoadingBaseButton>
      }
    />
  );
};
